@font-face {
  font-family: "anthon";
  src: url("../assets/fonts/anthon/anton-regular-webfont.woff2") format("woff2"),
    url("../assets/fonts/anthon/anton-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "luthier";
  src: url("../assets/fonts/luthier/Luthier-Regular.woff2") format("woff2"),
    url("../assets/fonts/luthier/Luthier-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.pageTitle {
  color: #fed352;
  font-family: anthon;
  letter-spacing: 0.2rem;
  font-size: 3rem;
  margin-top: -0.2rem;
}
.subTitle {
  color: black;
  font-family: anthon;
  letter-spacing: 0.3rem;
  font-size: 2rem;
  margin-top: -2rem;
}
.title {
  color: #512b5b;
  font-family: anthon;
  letter-spacing: 0.2rem;
  padding-top: 2rem;
}
.titleFernando {
  color: #512b5b;
  font-family: anthon;
  letter-spacing: 0.2rem;
}

.content {
  text-align: justify;
  color: #fed352;
  font-family: luthier;
  font-size: 24px;
}

.justifyContent {
  justify-content: center;
}
.paddingTop3 {
  padding-top: 3rem;
}
.paddingTop4 {
  padding-top: 4rem;
}
.lineSpacing {
  line-height: 1.8rem;
}
.footerTitle {
  color: #fed352;
  background-color: #512b5b;
  justify-content: center;
  text-align: center;
  padding-top: 3.6rem;
}
.bgSolutto {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.bgSoluttoCirco {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #11020b7d;
  padding-top: 3rem;
  padding-bottom:3rem;
}
.vh100{
  height: 100vh;
}

.bgSoluttoVolare {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000c7;
}



.bgSolutto2008 {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.frame {
  -webkit-animation: zoomeffect 10s infinite;
  -moz-animation: zoomeffect 10s infinite;
  animation: zoomeffect 10s infinite;
}
@-webkit-keyframes zoomeffect {
  0% {
    background-position: center;
    transform: scale(1, 1);
  }
  50% {
    background-position: center;
    transform: scale(1.01, 1.01);
  }
  100% {
    background-position: center;
    transform: scale(1, 1);
  }
}
@keyframes zoomeffect {
  0% {
    background-position: center;
    transform: scale(1, 1);
  }
  50% {
    background-position: center;
    transform: scale(1.01, 1.01);
  }
  100% {
    background-position: center;
    transform: scale(1, 1);
  }
}

.gradient {
  background-color: black;
  background-image: url("../assets/imgs/soluttoTrans2.png");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.gradientEspectaculos {
  background-color: black;
  background-image: url("../assets/imgs/bgEspectaculos.JPG");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.image-gallery {
  width: 100%;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

.containerSolutto{
  height: 100vh;
  background-color: rgba($color: #aaaaaa, $alpha: 0.04);
}

.imgBorder{
  border: 5px dashed #512b5b;
}

.bottomContent{
  display: inline-block;
  align-self: flex-end;
}
