.body {
  height: 100vh;
}

.bg {
  background-image: url("../assets/imgs/bgEspectaculos.JPG");
  height: 150vh;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.idiomaText {
  color: white;
  font-weight: 100;
  background-color: rgba(172, 36, 213, 0.404);
  text-align: center;
}
